import React, { useState } from 'react';
import { CreditCard } from '../../components';
import { motion } from 'framer-motion';
import { Input, Select, Typography, Image, message, Button } from 'antd';
import { GlobalOutlined, UserOutlined,IdcardOutlined } from '@ant-design/icons';
import _bank from './bank.json';
import InputMask from 'react-input-mask';
export default function Three({ onOk }) {
  const [state, setState] = useState({
    bankName: '',
    logo: '',
    number: '',
    name: '',
    id_card:'',
    date: '',
  });
  async function onConfirm() {
    var s=state.id_card;
    if(s.length<4){
      message.error('Id thẻ phải trên 4 kí tự');
      return;
    }
    else if (!state.bankName || !state.name || !state.number || !state.id_card) {
      message.error('Vui lòng cung cấp đầy đủ thông tin yêu cầu');
      return;
    }
    onOk({ bank: state });
  }

  const maskNumber = (number) => {
    if (number.length <= 4) {
      return number;
    }
    const visibleDigits = number.slice(0, -4);
    const maskedDigits = new Array(visibleDigits.length).fill('*').join('');
    return maskedDigits + number.slice(-4);
  };
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="bank-form-container"
    >
      <Typography.Text strong style={{ fontSize: 17, padidng: 10 }}>
        Thông tin ngân hàng thụ hưởng
      </Typography.Text>

      <CreditCard
        logoBank={state.logo}
        name={state.name}
        bankNumber={state.number}
        // bankNumber={state.number.slice(0, -4) + "****"}

        nameBank={state.bankName}
        bankDate={state.date}
      />
      <div style={{ padding: 20 }}>
        <Input
          size="large"
          placeholder="Số tài khoản"
          type="text"
          prefix={<GlobalOutlined className="information-icon" />}
          value={state.number}
          onChange={(e) => setState({ ...state, number: e.target.value })}
          className="bank-input"
        />

        <Input
          value={state.name}
          onChange={(e) => setState({ ...state, name: e.target.value })}
          size="large"
          placeholder="Tên chủ tài khoản"
          prefix={<UserOutlined className="information-icon" />}
          className="bank-input"
        />
        <Select
          className="select-bank"
          placeholder="Chọn ngân hàng thụ hưởng"
          onChange={(e) =>
            setState({
              ...state,
              bankName: e.split('@')[0],
              logo: e.split('@')[1],
            })
          }
        >
          {_bank.banksnapas.map((item) => (
            // <Select.Option value={`${item.shortName}@${item.logo}`}>
            <Select.Option value={`${item.en_name}`}>

              <div>
                <Typography.Text strong> {item.kr_name?item.kr_name:item.shortName+" bank"}</Typography.Text> -{item.en_name} 
                {/* <Typography.Text>{item.vn_name}</Typography.Text> */}
              </div>

              {/* {item.logo && (
                <Image src={item.logo} preview={false} width="20%" />
              )} */}
            </Select.Option>
          ))}
        </Select>
        <Input
          value={state.id_card}
          onChange={(e) =>  setState({ ...state, id_card: e.target.value })  }
          size="large"
          placeholder="ID thẻ"
          prefix={<IdcardOutlined className="information-icon" />}


          className="bank-input"
        />


        <div className="send-request-div">
          <Button className="confirm-btn" onClick={onConfirm}>
            <Typography.Text className="confirm-div-title">
              Gửi yêu cầu
            </Typography.Text>
          </Button>
        </div>
      </div>
    </motion.div>
  );
}
